.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  height: 200vh;
}

.sidebar {
  width: 220px;
  /* height: auto; */
  /* background: #f0f0f0; */
  border-right: solid 1px;
  border-color: color(srgb 0.0569 0.0182 0.626);
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  font-size: larger;
  color: color(srgb 0.0569 0.0182 0.626)
}

.sidebar-content {
  padding: 40px;
}

.main-content {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: color(srgb 0.0569 0.0182 0.626);

  &:hover {
    color: red;
  }
}

.sidebar .active-link {
  font-weight: bold;
  color: blue;
}

.sidebar .logoCZ {
  margin: 0;
  padding: 0;
  width: 200px;
}

.sidebar .logoCZ1 {
  margin: 0;
  padding: 0;
  width: 200px;
  padding-top: 500px;
}

.imagepp {
  margin-left: 0;
  padding-left: 0;
  text-align: left;
}

.imagepp1 {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  padding-top: 0;
  text-align: left;
}

.imagepp3 {
  margin-left: 0;
  padding-left: 0;
  margin-top: 100px;
  padding-top: 100px;
  text-align: left;
}

.imageifc {
  margin-left: 0;
  padding-left: 0;
  text-align: left;
  width: 100%;
}

.ifc-container {
  background-image: url('./IFC.png');
  /* Use the imported image */
  background-size: cover;
  /* Cover the entire container */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  height: 100vh;
  /* Set the height to fill the viewport */
  /* Other styles as needed */
}

.ifc-frame {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 55px;
  /* Adjust as needed */
  left: 1060px;
  /* Adjust as needed */
  width: 805px;
  height: 695px;
  background-color: #f0f0f0;
  /* Example background color */
  /* Other styles */
}

.buttonC {
  background-color: darkblue;
  top: 355px;
  position: absolute;
  left: 150px;

}

.login-container {
  /* background-image: url('./login.PNG'); */
  flex: row;
  justify-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  top: 0px;
  left: 0%; 
  /* width: 130%; */
  /* height: 695px; */
  /* background-color: darkblue; */
}